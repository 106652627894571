<template>
  <div
    ref="container"
    class="c-clear-on-coupon"
  >
    <template v-if="submitSuccess">
      <div
        v-if="submitSuccess"
        class="c-alert c-alert--success u-width-320"
      >
        {{ successfulMessageText }}
      </div>
    </template>
    <template v-else>
      <form
        v-if="!isDisabled && !isCouponTaken"
        class="c-clear-on-coupon__form"
        novalidate="true"
        @submit="onSubmit"
      >
        <div class="u-flex u-flex-align-start u-flex-shrink-0 u-flex-direction-column@mobile u-mb--m u-flex-wrap">
          <div class="c-clear-on-coupon__form-col u-flex u-flex-align-start u-flex-direction-column u-mr--s u-text-align--left">
            <input
              v-if="!model.useEmail"
              v-model="phoneNumber"
              type="tel"
              :placeholder="model.phoneNumberPlaceholder"
              name="phone"
              required
              pattern="[0-9]{10}"
              class="c-text-input c-clear-on-coupon__form-input u-themeable-border-color"
              :class="{
                'c-text-input--error': notValidPhone
              }"
            >
            <div
              v-if="model.useEmail"
              class="c-text-input-container u-width-100"
            >
              <Icon
                :icon="iconEmail"
                :size="16"
                class="c-text-input__icon u-ml--s"
                stroke="black"
              />
              <input
                v-model="email"
                type="email"
                :placeholder="model.emailPlaceholder"
                name="email"
                required
                class="c-text-input c-clear-on-coupon__form-input c-text-input--icon u-themeable-border-color"
                :class="{
                  'c-text-input--error': notValidEmail
                }"
              >
            </div>
            <transition
              name="fade"
            >
              <FormErrorLine
                v-if="submitError"
                class="u-mt--s u-mb--xs"
                :text="submitErrorText"
              />
              <FormErrorLine
                v-if="notValidEmail"
                class="u-mt--s u-mb--xs"
                :text="model.emailVerificationError"
              />
              <FormErrorLine
                v-if="notValidPhone"
                class="u-mt--s u-mb--xs"
                :text="model.phoneNumberVerificationError"
              />
            </transition>
          </div>
          <div
            class="c-clear-on-coupon__checbox-wrap u-flex-1 u-flex-align-start u-flex-direction-column u-mt--m@mobile  u-mt--s u-text-align--left"
            :class="{
              'u-ml--a': $mq == 'desktop'
            }"
          >
            <div
              class="c-checkbox"
            >
              <input
                id="clear-on-terms"
                v-model="termsAccepted"
                required
                type="checkbox"
                name="clear-on-terms"
                class="c-checkbox__input"
              >
              <label
                for="clear-on-terms"
                class="c-checkbox__label u-mr--xs u-themeable-border-color"
              >
                {{ model.consentText }}
              </label>
              <span
                class="c-clear-on-coupon__terms u-text-underline"
                @click.stop="showTermsConditions"
              >{{ model.consentTextLink }}</span>
              <span class="c-checkbox-icon">
                <Icon
                  :icon="iconCheck"
                />
              </span>
            </div>
            <transition
              name="fade"
            >
              <FormErrorLine
                v-if="checkboxError"
                class="u-mt--s u-mb--xs"
                :text="model.termsAndConditionsVerificationError"
              />
            </transition>
          </div>
        </div>
        <transition
          name="fade"
          mode="out-in"
        >
          <button
            v-if="!isLoading"
            key="button"
            class="c-button"
            :class="{
              'c-button--dark': darkForeground,
              'c-button--dark-inverted u-themeable-color': !darkForeground,

            }"
          >
            {{ model.buttonText }}
          </button>
          <Loader
            v-else
            key="loader"
            class="u-ml--a u-mr--a"
            size="medium"
            color="medium-gray"
          />
        </transition>
      </form>
      <div
        v-if="isDisabled"
        class="c-clear-on-coupon--disabled__text u-font-size-large"
      >
        {{ model.disabledCampaignMessageText }}
      </div>
      <div
        v-if="isCouponTaken"
        class="c-clear-on-coupon--disabled__text u-font-size-large"
      >
        {{ model.couponAlreadyTakenErrorText }}
      </div>
    </template>
    <Modal
      v-model="isModalVisible"
      :is-title-centered="false"
      class="u-text-black"
    >
      <div class="u-rich-text" v-html="model.termsAndConditions" />
    </Modal>
    <Recaptcha
      v-if="!model.disableRecaptcha"
      ref="recaptchaform"
      :sitekey="model.reCaptchaSiteKey"
      @submit="sendRequest"
    />
  </div>
</template>

<script>
import iconEmail from '@ds/svg/icons/stroke/email-action-unread.svg'
import iconCheck from '@ds/svg/icons/bold/check-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import FormErrorLine from '@/CVI/WebCore/components/FormErrorLine.vue'
import Recaptcha from '@/CVI/WebCore/components/Recaptcha.vue'
import api from '@/CVI/WebCore/lib/api'
import viewPortDetect from '@/CVI/WebCore/lib/in-viewport-detect'
import isValidEmail from '@/CVI/WebCore/lib/validation'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Modal,
    Icon,
    Recaptcha,
    FormErrorLine,
    Loader
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    darkForeground: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isModalVisible: false,
      isLoading: false,
      iconCheck,
      iconEmail,
      phoneNumber: '',
      email: '',
      notValidPhone: false,
      notValidEmail: false,
      checkboxError: false,
      termsAccepted: false,
      submitSuccess: false,
      successfulMessageText: '',
      submitError: false,
      submitErrorText: '',
      isDisabled: this.model.disableCampaign,
      isCouponTaken: this.model.userHasCoupon
    }
  },
  watch: {
    termsAccepted() {
      this.checkboxError = false
    },
    email() {
      this.notValidEmail = false
    },
    phoneNumber() {
      this.notValidPhone = false
    }
  },
  mounted() {
    this.trackImpression()
  },
  methods: {
    showTermsConditions() {
      this.isModalVisible = true
    },
    validatePhoneNumber() {
      const { phoneNumber } = this
      const { phoneNumberPlaceholder } = this.model
      const { length } = phoneNumberPlaceholder

      const reg = /^\d+$/
      if (phoneNumber.length == length && reg.test(phoneNumber)) {
        return true
      }
      return false
    },
    trackImpression() {
      const observeOptions = {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: 0.8
      }
      viewPortDetect.inViewport(this.$refs.container, () => {
        tracker.track({
          event: 'couponImpression',
          couponPlacement: window.gtmData.pageType
        })
      }, observeOptions)
    },
    async sendRequest(token) {
      const { blockId, useEmail, unexpectedErrorText } = this.model
      const { phoneNumber, email } = this

      this.isLoading = true
      try {
        const { data } = await api.couponCode.clearon({
          blockId,
          phoneNumber,
          email,
          useEmail,
          token
        })
        if (data.code != 200) {
          this.submitErrorText = data.message
          this.submitError = true
          tracker.track({
            event: 'digitalCoupon',
            couponStatus: 'error'
          })
        } else {
          this.successfulMessageText = data.message
          this.submitSuccess = true
          tracker.track({
            event: 'digitalCoupon',
            couponStatus: 'success'
          })
        }
      } catch (e) {
        this.submitErrorText = unexpectedErrorText
        this.submitError = true
        tracker.track({
          event: 'digitalCoupon',
          couponStatus: 'error'
        })
      }
      this.isLoading = false
    },
    async onSubmit(e) {
      e.preventDefault()
      const { email } = this

      let isValid = true
      if (!this.termsAccepted) {
        this.checkboxError = true
        isValid = false
      }

      if (this.model.useEmail) {
        if (!isValidEmail(email)) {
          this.notValidEmail = true
          isValid = false
        }
      } else if (!this.validatePhoneNumber()) {
        this.notValidPhone = true
        isValid = false
      }

      if (!isValid) {
        return
      }
      if (!this.model.disableRecaptcha) {
        this.$refs.recaptchaform.verify()
      } else {
        await this.sendRequest(null)
      }
    }
  }
}
</script>

<style lang="scss">
@use '@/CVI/WebCore/scss/clear-on-coupon.scss';
</style>
